<template>
  <div>
    <v-app>
      <v-container>
        <div class="page-card">
          <v-row>
            <v-col md="4">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Ara"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col md="2 ml-auto">
              <button
                type="button"
                v-if="isShow"
                class="btn btn-primary"
                @click="goAddCenter()"
              >
                Ekle
              </button>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="(globaladmin || cro)?headers:headers2"
                :items="desserts2"
                :search="search"
                :loading="loadings.table"
                loading-text="Yükleniyor..."
                :footer-props="{
                  'items-per-page-options': [20, 30, 40, -1],
                }"
                :items-per-page="20"
              >
                <template v-slot:[`item.edit`]="{ item }" v-if="isShow">
                  <v-icon class="fa-pencil-blue" @click="goAddCenterEdit(item)">
                    fas fa-pencil-alt
                  </v-icon>
                </template>
                <template v-slot:[`item.detay`]="{ item }">
                  <v-icon class=" text-warning" @click="goCenterView(item)"
                    >fas fa-chevron-circle-right
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-app>
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_SELECTEDPAGEITEMNAME } from "@/core/services/store/selectedpageitem.module";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
//import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "centerlist",
  data() {
    return {
      globaladmin: false,
      cro: false,
      user: false,
      isShow: false,
      loadings: {
        table: false,
      },
      search: "",
      desserts2: [
        {
          centerName: "",
          type: "",
          country: "",
          email: "",
          web: "",
          landPhone: "", // to do  2 servis cagrılacak
          specialtyDepartment: "",
          phoneNumber: "",
          district: "",
          city: "",
          detay: "",
          edit: "",
        },
      ],
      headers: [
        {
          text: "Merkez Adı",
          align: "left",
          value: "centerName",
        },
        { text: "Bölüm", value: "department" },
        { text: "Ülke", value: "country" },
        { text: "İl", value: "city" },
        { text: "Telefon", value: "landPhone" },
        { text: "Düzenle", value: "edit", sortable: false },
        { text: "Detay", value: "detay", sortable: false },
      ],
       headers2: [
        {
          text: "Merkez Adı",
          align: "left",
          value: "centerName",
        },      
        { text: "Ülke", value: "country" },
        { text: "İl", value: "city" },
        { text: "Telefon", value: "landPhone" },      
        { text: "Detay", value: "detay", sortable: false },
      ],
    };
  },

  mounted() {
     
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Merkez Listesi", route: "/centerlist" },
    ]);
    this.getCenterlist();
    this.globaladmin = this.isGlobalAdmin;
    this.cro = this.isCro;
    this.user = this.isNormalUser;
    this.isShow = this.globaladmin;
  },
  methods: {
    goAddCenter() {
      this.$store.dispatch(SET_SELECTEDPAGEITEMNAME, "Merkez Listesi");
      this.$router.push({ name: "centers" });
    },
    goAddCenterEdit(item) {
      localStorage.setItem("itemler", JSON.stringify(item));
      this.$store.dispatch(SET_SELECTEDPAGEITEMNAME, "Merkez Listesi");
      this.$router.push({ name: "centersedit" });
    },
    getCenterlist() {
      this.loadings.table = true;
      ApiService.get("/centers")
        .then(({ data }) => {
          //console.log("yeni kayıt içerde", data.result);
          this.desserts2 = data.result;
          //console.log("dessert", this.desserts2);
        })
        .catch((err) => {
          console.log("error", err);
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    goCenterView(item) {
      localStorage.setItem("itemler", JSON.stringify(item));
      this.$store.dispatch(SET_SELECTEDPAGEITEMNAME, "Merkez Listesi");
      this.$router.push({ name: "centersview" });
    },
  },
  computed: {
    ...mapGetters(["isGlobalAdmin", "isCro", "isNormalUser"]),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
  },
};
</script>
